import React, { Suspense, useContext, useEffect } from 'react';
import MainLayoutSkeleton from 'layout/main/skeleton';
import AuthContext from 'context/auth/context';
import MainProvider from 'context/main/provider';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import Rehydrated from 'components/Rehydrated';
import { initPrivateClient } from 'appsync/service';
import { Route } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import PageTitleSkeleton from 'components/PageTitle/skeleton';
import { StandaloneTableSkeleton } from 'components/TableSkeleton';
import { FunctionalAreaSkeleton } from 'pages/FunctionalAreas/components/skeleton';
import AutoLogout from 'components/AutoLogout/AutoLogout';
import dayjs from 'dayjs';
import AIInquiryChat, { AIInquiryProvider } from 'components/AIInquiryChat';
import { isSingleRole, isExperimentalUser } from 'hooks/useAuthorizer';
import IframedSite, { Routes, BASE_URL, OAUTH } from 'pages/Wiki';

const MainLayout = React.lazy(() => import('layout/main'));
const Users = React.lazy(() => import('pages/UserTable'));
/* const CreateUser = React.lazy(() => import('pages/User')); */
const TermsManagement = React.lazy(
  () => import('pages/Management/PartnersAndPrograms/components/terms'),
);
const Home = React.lazy(() => import('pages/HomePage'));
const Goals = React.lazy(() => import('pages/Management/Goals'));
const KeyDatesIndex = React.lazy(() => import('pages/Management/KeyDates/index'));
const PartnerIntakes = React.lazy(() => import('pages/PartnerIntakes'));
const PartnerProfileIndex = React.lazy(() => import('pages/PartnerProfile/index'));
const PartnersAndPrograms = React.lazy(() => import('pages/Management/PartnersAndPrograms/index'));
const EnrollmentSkyLobby = React.lazy(() => import('pages/FunctionalAreas/Enrollment'));
const StudentSupportSkyLobby = React.lazy(() => import('pages/FunctionalAreas/StudentSupport'));
const MarketingSkyLobby = React.lazy(() => import('pages/FunctionalAreas/Marketing'));
const YearOverYearSkyLobby = React.lazy(() => import('pages/FunctionalAreas/YearOverYear'));
const RententionSkyLobby = React.lazy(() => import('pages/FunctionalAreas/Retention'));
const ReportBuilderList = React.lazy(() => import('pages/ReportBuilder/pages/ReportsList'));
const ReportBuilderForm = React.lazy(() => import('pages/ReportBuilder/pages/ReportForm'));
const PerformanceReportDetailView = React.lazy(() => import('pages/PerformanceReportDetailView'));

const AgentsTable = React.lazy(() => import('pages/Agents/Table'));
const AgentsDetail = React.lazy(() => import('pages/Agents/Detail'));

const privateClient = initPrivateClient();

const defaultFallbackWrapper = (fallback: JSX.Element) => (
  <>
    <PageTitleSkeleton />
    {fallback}
  </>
);

const Main = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [isLogoutTimerStarted, setIsLogoutTimerStarted] = React.useState(false);
  const [isWikiLoaded, setIsWikiLoaded] = React.useState(false);

  const onLogout = async () => {
    await Auth.signOut();
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('lastActivity');
    auth.setStatus(false);
    auth.setUser(undefined);
    privateClient.cache.reset();
    history.push('/login');
  };

  const defaultRedirect = () => {
    if (isSingleRole('AgentAdmin', auth.user?.groups)) {
      return () => <Redirect to='/development/agents' />;
    } else if (isSingleRole('NoodleWikiUser', auth.user?.groups)) {
      return () => <Redirect to='/wiki' />;
    }
    return () => <Redirect to='/home' />;
  };

  const cardDetailRoutes = [
    '/speed-to-lead',
    '/prospect-pacing',
    '/cost-per-prospect',
    '/7d-engagement-rate',
    '/hours-to-first-call',
    '/coach-response-times',
    '/coach-resolution-times',
    '/paid-media-spend-pacing',
    '/daily-dials-and-talktime',
    '/enrollment-funnel-pacing',
    '/coach-reported-student-risk',
    '/7d-engaged-to-started-app-rate',
    '/students-awaiting-coach-outreach',
    '/student-academic-risk-indicators',
  ];

  useEffect(() => {
    const lastActivity = localStorage.getItem('lastActivity');
    if (lastActivity) {
      const lastActivityTime = dayjs(lastActivity);
      const currentTime = dayjs();
      const diff = currentTime.diff(lastActivityTime, 'minute');
      if (diff > 15) {
        onLogout();
      } else {
        setIsLogoutTimerStarted(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWikiLoaded && location.pathname.includes('/wiki')) {
      const loadSession = () => {
        const hiddenWindow = window.open(BASE_URL + OAUTH, '_blank', 'width=1,height=1');
        if (hiddenWindow) {
          hiddenWindow.onload = () => {
            console.log('onload!!');
            hiddenWindow.close();
            setIsWikiLoaded(true);
          };
          // Fallback in case onload doesn't trigger
          setTimeout(() => {
            console.log('timeout!!');
            hiddenWindow.close();
            setIsWikiLoaded(true);
          }, 5000);
        } else {
          console.error('Unable to open hidden window. Pop-ups might be blocked.');
          setIsWikiLoaded(true); // Set to true to prevent infinite loop
        }
      };

      loadSession();
    }
  }, [isWikiLoaded, location.pathname]);

  return (
    <Suspense fallback={<MainLayoutSkeleton empty />}>
      <ApolloProvider client={privateClient}>
        <Rehydrated appSyncClient={privateClient} fallback={<MainLayoutSkeleton />}>
          <MainProvider>
            <MainLayout auth={auth} onLogout={onLogout}>
              <AutoLogout onLogout={onLogout} isLogoutTimerStarted={isLogoutTimerStarted}>
                <ErrorBoundary>
                  <AIInquiryProvider>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/home' component={Home} />
                    </Suspense>
                    <Suspense
                      fallback={defaultFallbackWrapper(
                        <StandaloneTableSkeleton extraKey='users' />,
                      )}
                    >
                      <Route path='/users' component={Users} />
                    </Suspense>
                    {/* <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/user/:id' component={() => <CreateUser editable />} />
                    </Suspense> */}
                    {/*  <Suspense
                      fallback={defaultFallbackWrapper(
                        <StandaloneTableSkeleton extraKey='createUser' />,
                      )}
                    >
                      <Route path='/new/user' component={CreateUser} />
                    </Suspense> */}
                    {/* Enrollment functional area */}
                    <Suspense fallback={<FunctionalAreaSkeleton />}>
                      <Route exact path='/enrollment' component={EnrollmentSkyLobby} />
                    </Suspense>
                    {/* Student Support functional area */}
                    <Suspense fallback={<FunctionalAreaSkeleton />}>
                      <Route exact path='/marketing' component={MarketingSkyLobby} />
                    </Suspense>
                    {/* Year over year functional area */}
                    <Suspense fallback={<FunctionalAreaSkeleton />}>
                      <Route exact path='/year-over-year' component={YearOverYearSkyLobby} />
                    </Suspense>
                    {/* Student Support functional area */}
                    <Suspense fallback={<FunctionalAreaSkeleton />}>
                      <Route exact path='/student-support' component={StudentSupportSkyLobby} />
                    </Suspense>
                    {/* Retention functional area */}
                    <Suspense fallback={<FunctionalAreaSkeleton />}>
                      <Route exact path='/retention' component={RententionSkyLobby} />
                    </Suspense>

                    {/* Card detail views */}
                    {cardDetailRoutes.map((path) => (
                      <Suspense
                        key={`route-${path}`}
                        fallback={defaultFallbackWrapper(
                          <StandaloneTableSkeleton extraKey='detailView' />,
                        )}
                      >
                        <Route exact path={path} component={PerformanceReportDetailView} />
                      </Suspense>
                    ))}

                    {/* Management */}
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/management' component={PartnerProfileIndex} />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path='/management/partners-and-programs'
                        component={PartnersAndPrograms}
                      />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/management/terms' component={TermsManagement} />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/management/keydates' component={KeyDatesIndex} />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/management/goals' component={Goals} />
                    </Suspense>

                    {/* Admin */}
                    <Suspense
                      fallback={defaultFallbackWrapper(
                        <StandaloneTableSkeleton extraKey='users' />,
                      )}
                    >
                      <Route path='/admin/users' component={Users} />
                    </Suspense>
                    {/*  <Suspense
                      fallback={defaultFallbackWrapper(
                        <StandaloneTableSkeleton extraKey='createUser' />,
                      )}
                    >
                      <Route path='/admin/new/user' component={CreateUser} />
                    </Suspense> */}

                    {/* Partner intake */}
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path='/management/partners-and-programs/partner-intakes'
                        component={PartnerIntakes}
                      />
                    </Suspense>
                    {/* Report Builder */}
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/report' component={ReportBuilderList} />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/create-report' component={ReportBuilderForm} />
                    </Suspense>
                    {/* Agents */}
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/development/agents' component={AgentsTable} />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route exact path='/development/agents/:id' component={AgentsDetail} />
                    </Suspense>
                    {/* Noodle Wiki */}
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path='/wiki'
                        component={() => <IframedSite isWikiLoaded={isWikiLoaded} />}
                      />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path={`/wiki/${Routes.mission}`}
                        component={() => <IframedSite isWikiLoaded={isWikiLoaded} />}
                      />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path={`/wiki/${Routes.management}`}
                        component={() => <IframedSite isWikiLoaded={isWikiLoaded} />}
                      />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path={`/wiki/${Routes.dei}`}
                        component={() => <IframedSite isWikiLoaded={isWikiLoaded} />}
                      />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path={`/wiki/${Routes.ergs}`}
                        component={() => <IframedSite isWikiLoaded={isWikiLoaded} />}
                      />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path={`/wiki/${Routes.office}`}
                        component={() => <IframedSite isWikiLoaded={isWikiLoaded} />}
                      />
                    </Suspense>
                    <Suspense fallback={defaultFallbackWrapper(<></>)}>
                      <Route
                        exact
                        path={`/wiki/${Routes.faqs}`}
                        component={() => <IframedSite isWikiLoaded={isWikiLoaded} />}
                      />
                    </Suspense>
                    {/* defaultRedirect can vary depending on the user context */}
                    <Route exact path='/' component={defaultRedirect()} />
                    {auth.user?.groups && isExperimentalUser(auth.user.groups) && <AIInquiryChat />}
                  </AIInquiryProvider>
                </ErrorBoundary>
              </AutoLogout>
            </MainLayout>
          </MainProvider>
        </Rehydrated>
      </ApolloProvider>
    </Suspense>
  );
};

export default Main;
